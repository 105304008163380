.popup-container {
  overflow: auto;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.popup-button-container {
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: row;
}

.summary-table,
.summary-table tr,
.summary-table th,
.summary-table td {
  text-align: center;

  border: 1px solid black;
  border-collapse: collapse;
}

.summary-table th,
.summary-table td {
  padding: 5px;
}
.summary-table th {
  background-color: lightblue;
}

.summary-table-container {
  max-height: 200px;
  overflow-x: scroll;
}

.summary-table-container-main {
  max-height: 400px;
  display: flex;
  overflow-x: scroll;
  justify-content: center;
}

.textarea-noresize {
  resize: none;
}

.pricechange-comment-input {
  width: 100%;
}

.custom-lookup-results-container {
  max-height: 75vh;
  display: flex;
  overflow-y: scroll;
  justify-content: center;
}

.price-change-process-summary-container {
  max-height: 500px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
