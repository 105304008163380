// response styling screen sizes
$screen-new-handheld-max: 361px !default;
$screen-old-handheld-max: 320px !default;
$screen-desktop: 840px !default;

// response styling
@mixin new-handheld {
  @media (max-width: #{$screen-new-handheld-max}) {
    @content;
  }
}

@mixin new-handheld-up {
  @media (min-width: #{$screen-new-handheld-max}) {
    @content;
  }
}

@mixin old-handheld {
  @media (max-width: #{$screen-old-handheld-max}) {
    @content;
  }
}

@mixin old-handheld-up {
  @media (min-width: #{$screen-old-handheld-max}) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: #{$screen-desktop}) {
    @content;
  }
}

@mixin desktop-up {
  @media (min-width: #{$screen-desktop}) {
    @content;
  }
}

// color
$toym-blue: #0077dd !default;
$toym-blue-lighter: #66adeb !default;
$toym-white: #fff !default;
$toym-grey-lighter: #f0f0f0 !default;
$toym-grey: #cdcdcd !default;
$toym-black: #161616 !default;
$success: #2ecc71 !default;
