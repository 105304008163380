@import "./variables";

.searchbar-container {
  border: 1px solid $toym-grey;
  input {
    color: $toym-black;
    border: none;
    width: 100%;
    padding: 10px;

    font-size: 0.7em;

    &:focus,
    &:active {
      outline: none;
    }
  }
}
