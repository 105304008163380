@import "./variables";

.overlay-list-container {
  position: absolute;
  padding: 2px 10px;
  top: 20px;
  color: $toym-black;
  background-color: $toym-white;
  border: 1px solid $toym-black;
  border-radius: 3px;

  .overlay-list {
    padding: 2.5px;
  }
}
