@import "./variables";

.header-container {
  width: 100%;
  z-index: 999;

  .header-function-container {
    width: 100%;
    background-color: $toym-blue;

    padding: 5px 10px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .header-function-list-container {
      // width: 100%;
      max-width: 650px;
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: flex;
      flex-direction: row;

      .header-function-list {
        flex: 1 auto;
        padding: 5px 0;
        margin: 5px;
        border: 1px solid $toym-white;
        border-radius: 5px;

        color: $toym-white;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        svg {
          fill: $toym-white;
          width: 15px;
          margin: 0 5px;
        }

        span {
          padding: 0 5px;
        }

        &:hover {
          background-color: $toym-white;
          color: $toym-grey;

          svg {
            fill: $toym-grey;
          }
        }
      }
    }

    .header-title {
      width: 100%;
      padding: 0;
      margin: 0;
      color: white;
      text-align: center;
      font-size: 1.5em;
      font-weight: bold;
    }
  }
}
