@import "./variables";

.card-flexbox {
  display: flex; //the flex stylings here
  flex-wrap: wrap;
  align-items: stretch;
  .card-large-wrapper {
    flex: 0 0 calc(100% / 6 - 2px);
    margin: 1px;
    width: calc(100% / 6 - 2px);

    @include desktop {
      flex: 0 0 calc(100% / 2 - 2px);
      width: calc(100% / 2 - 2px);
    }

    border: 2px;
    border-style: solid;
    border-color: lightgrey;
    border-radius: 10px;

    &.selected {
      border: 2px solid dodgerblue;
    }

    &.approved {
      border: 2px solid forestgreen;
    }
  }

  .new-price-input {
    max-width: 65px;
    font-weight: bold;
  }

  .card-large-image-container {
    height: 150px;
    position: relative;

    @include desktop {
      height: 100px;
    }

    .card-check {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 30px;
      height: 30px;
    }
    .card-large-image {
      max-height: 150px;
      max-width: 200px;

      @include desktop {
        max-height: 100px;
        max-width: 150px;
      }
    }
  }

  .card-large-container {
    p {
      margin: 0;
      line-height: 1.1;
      font-size: 0.75em;
      font-weight: bold;

      @include desktop {
        font-size: 0.5em;
      }
    }
    .item-number {
      font-size: 0.75em;

      @include desktop {
        font-size: 0.5em;
      }
    }
    .price {
      font-size: 1.5em;

      @include desktop {
        font-size: 1em;
      }
    }

    .week-selector {
      width: 50px;
    }

    .card-large-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-color: lightgrey;
      padding: 2px;

      > :first-child {
        text-align: left;
        padding: 0 5px;
      }
      > :last-child {
        text-align: right;
        padding: 0 5px;
      }
    }

    .card-large-table {
      tbody {
        tr {
          text-align: right;
          td:nth-child(4) {
            color: dodgerblue;
          }
        }
      }
    }

    .card-large-table-SOH {
      tbody {
        tr {
          text-align: left;
          > :nth-child(2) {
            color: navy;
          }
        }
      }
    }

    .card-large-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: left;
      text-align: left;
      padding: 5px;
      padding-bottom: 0px;
    }

    .card-large-body-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 5px;

      div {
        padding: 0 5px;
      }

      .comment-button {
        width: 30px;
      }
      .comment-title {
        align-self: flex-end;
      }

      .span-5050 {
        width: 50%;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 2px;
        margin: 0;
        line-height: 1.1;
        font-size: 0.75em;
        font-weight: bold;

        @include desktop {
          font-size: 0.5em;
        }
      }

      .span-5050:first-child {
        text-align: left;
      }

      .span-5050:last-child {
        text-align: right;
      }
    }

    .card-large-body-row-table {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 5px;

      > :first-child {
        text-align: left;
        padding: 0 5px;
        max-width: 35%;
      }
      > :last-child {
        text-align: right;
        padding: 0 5px;
        min-width: 65%;
      }

      div {
        padding: 0 5px;
      }
    }

    .card-large-body-column {
      display: flex;
      flex-direction: column;
      justify-content: left;
      text-wrap: wrap;
      word-wrap: break-word;
      margin-bottom: 5px;
      div {
        padding: 2px 0px;
      }
    }

    .card-large-footer-table {
      display: flex;
      // justify-content: space-evenly;
      border: 2px;
      border-style: solid;
      border-color: gray;
      width: 100%;
      min-width: 0;
      font-size: small;
    }

    .card-large-footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}
// .card-small-wrapper {
//   flex: 0 0 calc(100% / 10 - 2px);
//   margin: 1px;

//   @include desktop {
//     flex: 0 0 calc(100% / 3 - 2px);
//   }

//   border: 2px;
//   border-style: solid;
//   border-color: lightgrey;
//   border-radius: 10px;
.card-small-wrapper {
  flex: 0 0 calc(100% / 10 - 2px);
  margin: 1px;
  width: calc(100% / 10 - 2px);

  @include desktop {
    flex: 0 0 calc(100% / 3 - 2px);
    width: calc(100% / 3 - 2px);
  }

  border: 2px;
  border-style: solid;
  border-color: lightgrey;
  border-radius: 10px;

  &.selected {
    border: 2px solid dodgerblue;
  }

  .card-small-image-container {
    height: 100px;

    @include desktop {
      height: 60px;
    }

    .card-small-image {
      max-height: 100px;
      max-width: 120px;

      @include desktop {
        max-height: 60px;
        max-width: 80px;
      }
    }
  }
  .card-small-container {
    p {
      margin: 0;
      line-height: 1.1;
      font-size: 0.6em;
      font-weight: bold;
      align-items: center;

      @include desktop {
        font-size: 0.5em;
      }
    }
    .item-number {
      font-size: 0.55em;

      @include desktop {
        font-size: 0.45em;
      }
    }

    .price {
      font-size: 1em;

      @include desktop {
        font-size: 0.75em;
      }
    }
    .card-small-title {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 2.5px;
      background-color: lightgrey;
    }

    .card-small-body-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 2.5px;

      div {
        padding: 0 2.5px;
      }
    }

    .card-small-footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}
