@import "./variables";

.offcanvas-header-container {
  background-color: $toym-grey-lighter;
  font-weight: bold;

  .offcanvas-title-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.offcanvas-filter-clearall {
  display: inline-block;
  text-decoration: underline;
  margin-bottom: 1em;
  &:hover {
    color: red;
  }
}

.offcanvas-button-container {
  display: flex;
  justify-content: space-between;
  flex: 0;
}

.slide-in {
  animation: slide-in 0.2s forwards;
  -webkit-animation: slide-in 0.2s forwards;
}

.slide-out {
  animation: slide-in 0.25s reverse;
  -webkit-animation: slide-in 0.25s reverse;
}

@keyframes slide-in {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  0% {
    -webkit-transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}
