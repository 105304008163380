.pdp-page-container {
  .pdp-image-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;

    .pdp-image-continer {
      display: flex;
      align-items: center;
      padding-left: 50px;
      padding-right: 50px;
      padding-bottom: 20px;
    }

    .pdp-image {
      max-height: 300px;
      object-fit: contain;
    }
  }

  .pdp-header-wrapper {
    align-items: center;
    background-color: lightgrey;
    width: 100%;
    display: flex;
    flex-direction: column;
    .pdp-header-container {
      width: 100%;
      max-width: 760px;
      padding: 0 10px;
      margin: 0 auto;
      .pdp-description {
        font-weight: bold;
        font-size: 20px;
        text-align: center;
      }
      .pdp-price {
        font-weight: bold;
        font-size: 16px;
        text-align: center;
      }
    }
  }

  .pdp-table {
    border: solid 1px;
    thead,
    tbody,
    tr,
    th,
    td {
      font-size: 14px;
      font-weight: 400;
      border: solid 1px;
      text-align: center;
      padding: 5px;
    }
  }

  .pdp-body-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    font-weight: bold;

    .pdp-body-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 5px;

      > :first-child {
        text-align: left;
        padding: 0 5px;
        width: 35%;
      }

      > :last-child {
        text-align: right;
        padding: 0 5px;
        width: 35%;
      }

      div {
        font-size: 13px;
        padding: 0 5px;
      }

      .pdp-body-row-title {
        font-size: 16px;
      }
    }
  }

  .pdp-container {
    width: 100%;
    max-width: 760px;
    padding: 0 10px;
    margin: 0 auto;
  }
  .pdp-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    align-content: center;
  }
}
