.list-image {
  max-height: 120px;
  max-width: 200px;
}

.itemlist-table {
  border: solid 1px;

  .sticky {
    background: white;
    top: 55px;
  }

  thead,
  tbody,
  tr,
  th,
  td {
    border: solid 1px;
    border-collapse: collapse;
    text-align: left;
  }
  .cell-pading-5 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .bold {
    font-weight: bold;
  }
}
