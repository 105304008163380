@import "./variables";

.sort-list-container {
  list-style-type: none;
  padding: 0;
  margin: 0;

  // for non-advanced sort
  li:not(.sort-item):not(.add-sort) {
    border: 1px solid $toym-grey;
    padding: 10px;
    border-radius: 5px;

    &:hover,
    &.selected {
      background-color: $toym-grey;
    }
  }

  // for advanced sort
  .sort-item-container {
    border: 1px solid $toym-grey;
    padding: 10px;
    border-radius: 5px;

    &:not(&.add-sort-item) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .sort-item {
        flex: 1;
      }
    }

    svg {
      padding: 2.5px 2.5px 2.5px 2.5px;
    }

    .add-sort {
      display: flex;
      flex-direction: row;
      align-items: center;

      .form-select {
        padding: 2.5px;
        margin: 2.5px;
      }

      > :first-child {
        flex: 1 1 70%;
      }

      > :nth-child(2) {
        flex: 1 1 25%;
      }

      > :last-child {
        flex: 1 1 5%;
      }
    }
  }
}
