@import "~bootstrap/scss/bootstrap";
@import-normalize;

@import "./scss/header";
@import "./scss/variables";
@import "./scss/card";
@import "./scss/offcanvas";
@import "./scss/searchbar";
@import "./scss/sort";
@import "./scss/list";
@import "./scss/overlay";
@import "./scss/PDP";
@import "./scss/popup";

input.form-password {
  // font-family: "password";
  text-security: disc;
  -webkit-text-security: disc;
  -moz-text-security: disc;
}

/* generic */
a {
  text-decoration: none;
}

.background-lightblue {
  background-color: lightblue;
}

.background-salmon {
  background-color: salmon;
}

.hidden {
  display: none;
}

.desktop-only-hidden {
  @include desktop-up {
    display: none !important;
  }
}

.mobile-only-hidden {
  @include desktop {
    display: none !important;
  }
}

.color-red {
  color: red;
}

.color-lightgreen {
  color: lightgreen;
}

.color-forestgreen {
  color: forestgreen;
}

.color-dodgerblue {
  color: dodgerblue;
}

.color-darkviolet {
  color: darkviolet;
}

.color-success {
  color: $success;
}

.text-align-center {
  text-align: center;
}

.align-right {
  margin-left: auto;
  margin-right: 0;
}

.margin-auto {
  margin: 0 auto;
}

.default-alignment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.row-alignment {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
}

.header {
  color: #f1f1f1;
  background-color: #3581df;
  font-weight: bold;
  padding: 5px 0;
  text-align: center;
}

.top {
  font-weight: bold;
  display: flex;
  flex-direction: row;
  // justify-content: center;
}

.padding-5 {
  padding: 5px 0;
}

.padding-0 {
  padding: 0px;
}

.margin-5 {
  padding: 5px 0;
}

.no-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.home-button-list {
  padding: 10px 0;
  align-items: center;
}

.handheld-body-container,
.desktop-body-container,
.desktop-incomingdeliveriesbody-container {
  min-height: 360px;
  padding: 5px;
  margin: 0 auto;
}

.handheld-body-container {
  min-width: 320px;
  max-width: 361px;

  width: 100%;
}

.error-message {
  color: red;
  padding: 0;
  margin: 0;
}

.detail-submit-button {
  // width: 50%;
  border-radius: 5px;

  border: 1px solid black;

  padding: 5px 10px;

  font-size: 16px;
  font-weight: 600;

  background-color: #3581df;

  color: white;
}

/* Sign In */
.signin-table {
  width: 100%;

  border-collapse: collapse;

  font-size: 20px;
}

.signin-table tr {
  height: 2em;

  td {
    div:not(.checkbox-row) {
      display: flex;
      flex-direction: columm;
      align-items: center;

      p {
        font-size: 1rem;
        margin: 0;
        padding: 0;
      }

      > :first-child {
        flex: 0 0 30%;
      }

      > :nth-child(2) {
        flex: 0 0 70%;
      }
    }
  }
}

.signin-table input,
.signin-login-button {
  width: 100%;
  border-radius: 5px;

  border: 1px solid black;

  padding: 5px 10px;

  font-size: 16px;
  font-weight: 600;
}

.signin-login-button {
  background-color: #3581df;

  color: white;
}

.btnHover:hover {
  cursor: pointer;
}

/* ImagesImportTool */
.imagesimporttool-container {
  width: 100%;
  max-width: 980px;
  margin: 10px auto;

  .fileupload-area {
    width: 100%;
    padding: 5px 10px;

    border-radius: 5px;

    display: flex;
    flex-direction: row;
    align-items: center;

    background-color: lightgrey;

    p {
      margin: 0;
      margin-left: 10px;
    }
  }

  .imagesimporttool-filelist {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      .imagesimporttool-row {
        display: flex;
        flex-direction: row;
        align-items: center;

        padding: 10px;
        &.file-row {
          border: 1px solid black;
          border-radius: 5px;
          border-collapse: collapse;
        }

        p {
          margin: 0;
        }

        img {
          width: 100%;
          max-width: 100px;
        }

        > :first-child {
          flex: 0 0 15%;
          text-align: left;
        }

        > :nth-child(2) {
          flex: 0 0 30%;
          text-align: left;
        }

        > :nth-child(3) {
          flex: 0 0 10%;
          text-align: left;
        }

        > :nth-child(4) {
          flex: 0 0 35%;
          text-align: left;
        }
      }
    }
  }

  .home-button-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.main-container {
  justify-content: center;
  max-width: 2000px;
  text-align: center;
  margin: 0 auto;
  .filter-header-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 5px;
  }
  .filter-container {
    width: 16%;
    text-align: left;
    justify-content: left;
    height: 100%;
  }

  .grid-container {
    flex: 1;
    .sort-container {
      display: flex;
      flex-direction: row;

      align-self: right;
    }
  }
}

.accordion-header-scroll {
  overflow-y: scroll;
}

.accordion-scroll {
  overflow-y: scroll;
  max-height: 300px;
}

.pretty-link {
  text-decoration: none;
  color: black;
}

.align-space {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.sticky {
  position: sticky;
  top: 0;
}

.padding-3 {
  padding: 3px;
}

.padding-15 {
  padding: 15px;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;

  @include desktop {
    left: 45%;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.dailysalesperformance-total-container {
  p {
    padding: 0;
    margin: 0;
  }

  background-color: lightgrey;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;

  .dailysalesperformance-total {
    flex: 0 0 calc(100% / 3);
    text-align: center;

    padding: 2.5px;
  }
}

.no-highlight {
  user-select: none;
  -webkit-user-select: none;
}

.itemdetails-table,
.itemdetails-table tr,
.itemdetails-table th,
.itemdetails-table td {
  text-align: center;

  border: 1px solid black;
  border-collapse: collapse;
}

.itemdetails-table th,
.itemdetails-table td {
  padding: 5px;
}

.itemdetails-table {
  width: 100%;
  margin-top: 10px;

  th {
    svg {
      margin-left: 10px;

      &.no-margin {
        margin-left: 0;
      }
    }
  }

  tbody {
    svg {
      padding: 0 5px;
      margin: 0;
    }
  }
}

.itemdetails-table input {
  width: 100px;

  &.full-width {
    width: 100%;
  }
}
